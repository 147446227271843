import { isEmpty } from 'lodash-es';
import { ACTIVITY_TYPES, MODULES } from 'state-domains/constants';
import {
    Activity,
    ActivityMap,
    DefaultPermission,
    FilterTypes,
    Header,
    HeaderListSpec,
    HeaderType,
    HeaderTypesDict,
    List,
    Project,
    UserWithType,
} from 'state-domains/domain';
import { CheckboxSelectMenuOption } from 'src/components/CheckboxSelectMenu/CheckboxSelectMenu.types';
import { FilterOption } from 'src/components/FilterButton/FilterPopup/FilterPopup.types';

import { HeaderFieldInfo, HeaderFieldInfoValues } from './types';

const sortByIndex = (a: any, b: any) => a.index - b.index;

export const getActivitesInProjectForUser = (
    project: Project,
    user: string,
    activities: Record<string, ActivityMap>,
    activityType: string,
    defaultPermission: DefaultPermission,
    moduleId: string,
    moduleAction = '',
) => {
    const allowedActivities: Activity[] = [];

    if (isEmpty(project)) return allowedActivities;

    const projectActivities: Record<string, Activity> = Object.assign(
        {},
        ...Object.values(project.activities)
            .sort((a: Activity, b: Activity) => a.index - b.index)
            .map((x) => ({ [x.activity]: x })),
    );

    const defaultPermissionAccess = moduleAction
        ? defaultPermission?.moduleActions.filter((x) => x.code === moduleAction).length > 0
        : defaultPermission?.access;
    let moduleAccess = defaultPermissionAccess;

    if (project.users?.[user]?.userPermissions?.[moduleId]) {
        const projectUserPermissions = project.users[user].userPermissions[moduleId];
        moduleAccess = moduleAction
            ? projectUserPermissions.moduleActions.filter((x) => x.code === moduleAction).length > 0
            : projectUserPermissions.access;
    }

    for (const key of Object.keys(projectActivities)) {
        if (!(key in activities)) continue;

        let addActivity = (project.users[user] && moduleAccess) || defaultPermissionAccess;

        if ((project.users[user] && moduleAccess) || projectActivities[key].users?.[user]) {
            if (projectActivities[key].users?.[user]?.userPermissions?.[moduleId]) {
                const modulePermissions =
                    projectActivities[key].users[user].userPermissions?.[moduleId];
                const module =
                    modulePermissions?.moduleActions?.find((x: any) => x.code === moduleAction) ??
                    ({} as any);
                addActivity = moduleAction
                    ? module.access && modulePermissions?.access
                    : modulePermissions?.access;
            }

            if (addActivity && activities[key]?.type === activityType) {
                allowedActivities.push(projectActivities[key]);
            }
        }
    }

    return allowedActivities;
};

export const getActivitiesForAllCollarsInProjectForUser = (
    project: Project,
    user: string,
    activities: Record<string, ActivityMap>,
    defaultPermission: { [key in ACTIVITY_TYPES]: DefaultPermission },
) => {
    const drillholeActivities = getActivitesInProjectForUser(
        project,
        user,
        activities,
        ACTIVITY_TYPES.DRILLING,
        defaultPermission[ACTIVITY_TYPES.DRILLING],
        MODULES.DRILL_HOLES,
    );
    const pointActivities = getActivitesInProjectForUser(
        project,
        user,
        activities,
        ACTIVITY_TYPES.POINT,
        defaultPermission[ACTIVITY_TYPES.POINT],
        MODULES.POINT_SAMPLES,
    );
    return [...drillholeActivities, ...pointActivities];
};

export const createUserList = (
    project: Project,
    activities: Activity[],
    usersCollection: Record<string, UserWithType>,
) =>
    getAllUsersForProject(project, activities).reduce((accum: any[], id) => {
        if (usersCollection[id]) {
            accum.push({ id, value: usersCollection[id].profile?.name ?? '' });
        }

        return accum;
    }, []);

export const getAllUsersForProject = (project: Project, activities: Activity[]) => {
    if (isEmpty(project)) return [];

    const users = project?.users ? Object.keys(project.users) : [];

    activities.forEach((activity) => {
        users.push(...(activity.users ? Object.keys(activity.users) : []));

        if (activity.groups) {
            Object.values(activity.groups).forEach((group) =>
                users.push(...Object.keys(group.users)),
            );
        }
    });

    return [...new Set(users)];
};

export const getUsersForActivities = (
    project: Project,
    activities: Activity[],
    usersCollection: Record<string, UserWithType>,
) => {
    if (isEmpty(project)) return {};

    const userTree: Record<string, { id: string; value: string; email: string }[]> = {};

    activities.forEach((activity) => {
        userTree[activity.id] = [];

        const users = project && project.users ? Object.keys(project.users) : [];

        users.push(...(activity.users ? Object.keys(activity.users) : []));

        if (activity.groups) {
            Object.values(activity.groups).forEach((group) =>
                users.push(...Object.keys(group.users)),
            );
        }

        [...new Set(users)].forEach((id) => {
            if (usersCollection[id]) {
                userTree[activity.id].push({
                    id,
                    value: usersCollection[id].profile.name,
                    email: usersCollection[id].email,
                });
            }
        });
    });

    return userTree;
};

export const findCommonUsers = (
    activities: string[],
    userTree: Record<string, FilterOption[]>,
) => {
    if (isEmpty(activities) || isEmpty(userTree)) return [];

    const users: Record<string, CheckboxSelectMenuOption> = {};

    activities.forEach((activity) => {
        userTree[activity]?.forEach((user) => {
            users[user.id] = user;
        });
    });
    return Object.values(users);
};

export const getHeaderFields = (header: Header, headerTypes: HeaderTypesDict) => {
    const fields: HeaderType[] = [];

    Object.values(header.sections)
        .sort(sortByIndex)
        .forEach((section) => {
            Object.values(section.fields)
                .sort(sortByIndex)
                .forEach((field) => {
                    fields.push(headerTypes[field.field]);
                });
        });

    return fields;
};

export const getListRows = (list: List, headerListSpec: HeaderListSpec = {} as HeaderListSpec) => {
    if (!list || !headerListSpec) return [];

    const validRows: HeaderFieldInfoValues[] = [];
    const inUseColumn = list.inuseColumn;

    for (const rowId in list.rows) {
        if (
            !list.rows[rowId].values[inUseColumn] ||
            (!isEmpty(headerListSpec) && !headerListSpec.rows[rowId])
        ) {
            continue;
        }

        validRows.push({
            id: rowId,
            value: list.rows[rowId].values[list.primaryColumn],
            index: list.rows[rowId].rank,
        });
    }

    return validRows;
};

export const buildHeaderOptionsForActivity = (
    allowedActivities: string[],
    activities: Record<string, ActivityMap>,
    headers: Record<string, Header>,
    headerTypes: HeaderTypesDict,
    listCollection: Record<string, List>,
) => {
    const activityOptions: Record<string, HeaderFieldInfo[]> = {};

    for (const activityId of allowedActivities) {
        const activity = activities[activityId];

        if (!activity) continue;

        const header = headers[activity.header];
        const headerFieldCollection: HeaderFieldInfo[] = [];
        const headerFields = header ? getHeaderFields(header, headerTypes) : [];
        const headerListSpecs = activity.headerListSpecs;

        headerFields.forEach((value) => {
            if (isEmpty(value)) {
                return;
            }
            let headerFieldInfo: HeaderFieldInfo = {
                id: value.id,
                name: value.name,
                label: value.name,
                type: value.type,
                dynamicFilter: true,
            };

            if (value.list && listCollection && headerListSpecs && headerListSpecs[value.id]) {
                headerFieldInfo = {
                    ...headerFieldInfo,
                    values: getListRows(listCollection[value.list], headerListSpecs[value.id]),
                };
            }

            headerFieldCollection.push(headerFieldInfo);
        });

        activityOptions[activityId] = headerFieldCollection;
    }

    return activityOptions;
};

export const createDynamicFilters = (
    activityIds: string[],
    optionTree: Record<string, HeaderFieldInfo[]>,
) => {
    if (isEmpty(optionTree) || isEmpty(activityIds)) return [];

    const dynamicFilters = [];
    const fields: Record<string, HeaderFieldInfo> = {};
    const fieldCount: Record<string, number> = {};
    const headerLists: Record<string, Record<string, HeaderFieldInfoValues>> = {};

    activityIds.forEach((activityId) => {
        optionTree[activityId]?.forEach((field) => {
            if (fieldCount[field.id]) {
                fieldCount[field.id] += 1;
            } else {
                fieldCount[field.id] = 1;
                fields[field.id] = { ...field };
            }

            if (field.type === FilterTypes.LIST) {
                headerLists[field.id] = Object.assign(
                    headerLists[field.id] ?? {},
                    ...(field.values ?? []).map((x) => ({ [x.id]: x })),
                );
            }
        });
    });

    for (const fieldId in fieldCount) {
        if (fieldCount[fieldId] !== activityIds.length) continue;

        if (fieldId in headerLists) {
            fields[fieldId].values = Object.values(headerLists[fieldId]).sort(sortByIndex);
        }

        dynamicFilters.push(fields[fieldId]);
    }

    return dynamicFilters;
};
